<template>
    <div class="layout dashboard-box text-xs-center align-center justify-center row fill-height">
        <div class="flex wrap-content xs12 md8 mb-4 mt-4 pa-5">
            <h1 class="primary--text font-weight-regular">
                Page Not Found
            </h1>
            <p>
                The page you were looking for could not be found.  Please use the menu to find another page or
                <a href="mailto:support@buyerbridge.io">contact support</a> if you've recevied this
                message in error.
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        title: 'Page Not Found',
        data() {
            return {
                nothing: 'Nothing to see here.'
            };
        }
    };
</script>
